@import '../../styles/colors';
@import '../../styles/general';

.panel {
  background: $off-white;
  box-shadow: $sm-box-shadow;
  padding: 2rem 2.5rem;
  border-radius: 0.25rem;
  overflow: hidden;

  &.raised {
    box-shadow: $lg-box-shadow;
  }

  &.skinny {
    padding: 1.25rem 1.5rem;
  }

  &.none {
    padding: 0;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  h1 {
    display: flex;
    align-items: center;

    svg {
      margin-right: 1.5rem;
    }
  }
}

.panelRow {
  display: flex;
  margin: 0 -0.625rem;

  & > * {
    flex: 1;
    margin: 0 0.625rem;
  }
}

@media only screen and (max-width: 650px) {
  .panel {
    padding: 1.25rem 1.25rem 1rem 1.25rem;
  }

  .panelRow {
    flex-direction: column;
    margin: -0.625rem 0;

    & > * {
      margin: 0.625rem 0;
    }
  }
}
