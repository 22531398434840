.redeem-page-image-container {
  display: flex;
  align-items: center;
}

.header-container {
  display: flex;
  justify-content: space-between;

  h1 {
    margin: 0;
  }
}

.redeem-page-image {
  width: 84px;
  height: 64px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 2rem;
}

.redeem-page-amount-container {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  align-self: stretch;
  margin: 1rem 0;
}

.redeem-page-amount-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.redeem-page-amount-value {
  font-size: 3rem;
  color: #2ecc71;
}

.redeem-page-amount-label {
  color: #222;
  padding: 0.5rem;
}

.redeem-page-header {
  margin-top: 0.5rem;
}
