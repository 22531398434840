@import '../../styles/colors';
@import '../../styles/general';

.confirmation-modal-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  visibility: visible;
  transition: 200ms all;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &.hidden {
    visibility: hidden;
    opacity: 0;

    .confirmation-modal-window {
      transform: translateY(100px);
    }
  }
}

.confirmation-modal-window {
  display: inline-block;
  box-sizing: border-box;
  margin: 1.3rem;
  background-color: white;
  width: calc(100% - 2rem);
  max-width: 24rem;
  border-radius: 0.5rem;
  box-shadow: $lg-box-shadow;
  z-index: 99999;
  transition: 200ms all;
  transform: translateY(0);
  overflow: hidden;
}

.confirmation-modal-icon-header {
  background-color: $black;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-modal-content {
  padding: 2rem 2.6rem;
}

.confirmation-modal-header {
  text-align: center;
  font-weight: 400;
  margin: 0 0.5 1rem;
  font-size: 1rem;
  line-height: 1.5;
  word-wrap: normal;
}
.email-link {
  text-align: center;
  font-weight: 400;

  font-size: 0.99rem;
  line-height: 1.5;
  word-wrap: break-word;
}

.confirmation-modal-value {
  color: $dark-red;
}

.confirmation-modal-cause {
  font-size: 1.5rem;
}

.confirmation-modal-description {
  font-size: 1rem;
  text-align: center;
  line-height: 1;
  margin: 0.5rem 1.5rem;
}

.confirmation-modal-button-section {
  flex: 0 1 auto;
  display: flex;
  justify-content: space-around;
  padding: 0 1.5rem 1.5rem;
}

.confirmation-modal-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20rem;
}

.confirmation-modal-inline-cause {
  font-weight: 700;
}

.confirmation-modal-title {
  text-align: center;
}

.confirmation-modal-sponsor {
  width: 100%;
  border-top: 1px solid $white-gray;
  border-bottom: 1px solid $white-gray;
  padding: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmation-modal-sponsor-logo {
  height: 4rem;
  margin-right: 1.25rem;
}

.confirmation-modal-sponsor-message {
  margin: 0;
  font-size: 1rem;
  flex-shrink: 1;
}

@media (max-width: 600px) {
  .confirmation-modal-sponsor-message {
    font-size: 0.875rem;
  }
}
