@import '../../styles/colors';
@import '../../styles/general';

.login-container {
  position: relative;
  height: 100%;
  margin-top: 5rem;
}

.login-box {
  top: 50%;
  transform: translateY(0%);
  padding: 15px;
  background-color: #fff;
  box-shadow: 1px 20px 20px rgb(131, 130, 130);
  border-radius: 5px;
  border-top: 1px solid #e9e9e9;
}

.selected {
  border-bottom: 1.5px solid rgb(160, 158, 158) !important;
}

.login-header {
  text-align: center;
  background-color: #fdedeb;
  width: 1;
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 15px;
}

.login-header img {
  width: 75px;
}

.input-icon i {
  position: absolute;
}
.input-icon {
  width: 100%;
}

.icon {
  padding: 5px;
  min-width: 40px;
}

.form-control {
  width: 100%;
  padding: 10px;
  text-align: flex-start;
  padding-left: 30px;
}
.buttonDiv {
  margin: 25;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

.login-signup-selection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}

.signup-btn {
  color: #e74c3c;
  font-size: 2rem;
  font-weight: 100;
}
.reset-pw-form {
  color: #e74c3c;
  font-size: 2rem;
  font-weight: 100;
  margin: 15px;
  justify-content: center;
  text-align: center;
}

.reset-pw {
  color: black;
  text-decoration: underline;
}

#error-message {
  white-space: break-spaces;
}
.hide-error {
  display: none;
  white-space: break-spaces;
}

.btn-danger {
  background-color: #e74c3c;
}

@media screen and (max-device-width: 640px), screen and (max-width: 640px) {
  .login-container {
    position: relative;
    height: 100%;
    margin-top: 0.25rem;
  }

  .login-box {
    top: 50%;
    transform: translateY(0%);
    padding: 15px;
    background-color: #fff;
    box-shadow: 1px 20px 20px rgb(131, 130, 130);
    border-radius: 5px;
    border-top: 1px solid #e9e9e9;
  }
}
