@import '../../styles/general';

.page {
  margin: 0 $gutter-width;
  padding: $gutter-width;
  max-width: 64rem;
  width: 100%;
  flex-grow: 1;

  &.small {
    max-width: 52rem;
  }

  &.large {
    max-width: 64rem;
  }

  & > * {
    margin-bottom: $gutter-width;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
