@import-normalize;

body {
  margin: 0;
  font-family: 'Overpass';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  // height: 100%;
}

code {
  font-family: 'Overpass';
}

html {
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }
}

html,
body,
#root {
  height: 100%;
}
